import { Lambda } from '@aws-sdk/client-lambda';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

import SubmitButton from '~/components/common/submit-button';

import { AWS_LAMBDA_NAME } from '~/utils/constants/app-constants';

export default {
  components: { SubmitButton },
  data () {
    return {
      lambda: null,
      loading: false,
      formRef: 'contactFormRef',
      emailSubject: '',
      formData: {
        email: ''
      },
      isSuccessSubmit: false,
      successAction: 'redirect' // or nothing
    };
  },
  mounted() {
    this.initLambda();
  },
  methods: {
    initLambda() {
      this.lambda = new Lambda({
        apiVersion: '2015-03-31',
        region: 'us-east-1',
        credentials: fromCognitoIdentityPool({
          identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
          clientConfig: { region: 'us-east-1' }
        })
      });
    },
    async onSubmit() {
      const isValid = await this.$refs[this.formRef].validate();
      if (!isValid) {
        return;
      }

      this.onButtonClick();

      this.loading = true;
      //
      //  Stringify JSON
      //
      const data = JSON.stringify({
        text: this.getEmailTextBody(),
        subject: this.emailSubject,
        replyTo: this.formData.email
      });

      //
      //  Convert string to an ArrayBuffer
      //
      const encoder = new TextEncoder();
      const arrayBuffer = encoder.encode(data);
      //
      //  Prepare parameters for putObject function
      //
      const params = {
        FunctionName: AWS_LAMBDA_NAME,
        Payload: arrayBuffer
      };
      //
      //  Call putObject to send json to lambda Bucket
      //
      this.lambda.invoke(params)
        .then(() => {
          this.isSuccessSubmit = true;
          if (this.successAction === 'redirect') {
            this.$router.push({ name: 'thank-you' });
          }
        })
        .catch((error) => {
          console.info(params);
          return console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEmailTextBody() {
      return '';
    },
    onButtonClick() {}
  }
};
