<template>
    <v-btn
        block
        color="primary"
        class="submit-button"
        elevation="2"
        :loading="loading"
        type="submit"
        x-large
    >
        <template #loader>
            <v-progress-circular indeterminate size="23" width="2" class="mr-3" />
            <span v-text="buttonText" />
        </template>
        {{ buttonText }}
    </v-btn>
</template>

<script>
export default {
    name: 'SubmitButton',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonText() {
            return this.loading ? 'submitting' : 'Send Request';
        }
    }
};
</script>

<style scoped lang="scss">
.submit-button {
    .v-btn__content {
        font-weight: 600;
        font-size: 1.0625rem;
        letter-spacing: initial;
    }

    &.v-btn--disabled {
        background-color: #007bff !important;
        border-color: #007bff !important;
    }
}
</style>
