export default {
  head() {
    const TITLE_TEMPLATE = `${this.headData.title} by 0x4447`;

    const linkTags = [
      {
        rel: 'canonical',
        href: `https://products.0x4447.com${this.$route.path}`
      }
    ];

    if (this.$route.params.item) {
      linkTags.push({
        hid: 'shortcut-icon',
        rel: 'shortcut icon',
        href: `/favicons/${this.$route.params.item}.ico`
      });
    } else if (this.bodyData?.imgPath) {
      linkTags.push({
        hid: 'shortcut-icon',
        rel: 'shortcut icon',
        href: this.bodyData.imgPath
      });
    }

    return {
      title: TITLE_TEMPLATE,
      link: linkTags,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.headData.description
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.headData.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: TITLE_TEMPLATE
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'og:product'
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.headData.ogImage
            ? `/public/img/${this.headData.ogImage}`
            : '/public/img/og/_default.png'
        }
      ]
    };
  }
};
