<template>
    <div class="two-columns-page">
        <v-container>
            <v-row>
                <v-col class="col-12 col-md-5">
                    <!--title section-->
                    <div class="d-flex flex-column mb-4 mb-md-6">
                        <h1 class="display-1" v-text="title" />
                        <h2 class="display-1 two-columns-page-title--blue" v-text="subtitle" />
                    </div>

                    <div class="d-flex">
                        <nuxt-img
                            v-if="image"
                            :src="image"
                            :alt="title"
                            width="50"
                            height="50"
                            class="two-columns-image mr-4"
                            format="webp"
                        />
                        <p class="two-columns-page-content__description mb-0" v-html="description" />
                    </div>
                </v-col>
                <v-col class="col-12 col-md-7">
                    <slot name="right" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'TwoColumnsPage',
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss">
.two-columns-page {
    .two-columns-page-title--blue {
        color: $hover-link;
    }

    .two-columns-page-content__description {
        font-size: 1.1875rem;
        font-weight: 400;
        color: $text-muted;
    }

    .two-columns-image {
        width: 50px;
        height: 50px;
    }
}
</style>
